@mixin for_iphone_5 {
  @media (min-width: 320px) and (max-width: 568px) {
    @content;
  }
}
@mixin for_iphone_6_7_8 {
  @media (min-width: 375px) and (max-width: 667px) {
    @content;
  }
}
@mixin for_iphone_6_7_8_plus {
  @media (min-width: 414px) and (max-width: 736px) {
    @content;
  }
}
@mixin for_iphone_X {
  @media (min-width: 375px) and (max-width: 812px) {
    @content;
  }
}

@mixin for_galaxi_S5 {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin for_small_phones {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin for_small_devices {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-notebook {
  @media (min-width: 768px) and (max-width: 1366px) {
    @content;
  }
}

@mixin for_medium_devices {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for_large_devices {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for_x-large_devices {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for_x-large_devices {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for_1366x768 {
  @media (min-width: 1330px) and (max-width: 1450px) {
    @content;
  }
}
@mixin for_1440x900 {
  @media (min-width: 1440px) and (max-width: 1450px) {
    @content;
  }
}
@mixin for_1920x1080 {
  @media (min-width: 1450px) {
    @content;
  }
}
