@import "./mixins";
@import "./variables";

.btn {
  width: 100%;
  border-radius: 2rem;
  font-size: 1.6rem;
}

.btn-valid {
  width: 182px;
  height: 40px;
  border-radius: 10px;
  &:hover {
    opacity: 0.7;
  }
}
.btn-invalid {
  opacity: 0.5;
  width: 182px;
  height: 40px;
  border-radius: 10px;
}

.btn-submit {
  @include button($accent-color, $light-color, 1rem, true, $primary-color);
}
.btn-save {
  @include button($primary-color, $light-color, 1rem, true, $primary-color);
}

.btn-back {
  width: 182px;
  height: 40px;
  border-radius: 10px;
  border: solid 1px #d9dae1 !important;
  &:hover {
    background-color: #d9dae1;
    color: #121212 !important;
  }
}

.btn-cancel {
  @include button($invalid-red, $light-color, 1rem, true, $primary-color);
}
.btn-disabled {
  @include button($bg-gray-3, $light-color, 1rem);
}

.btn-icon {
  width: 10%;
  color: $primary-color;
}

.spacer {
  flex: 1 1 auto;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 14px;
}
