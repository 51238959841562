$light-color: #fff;
$dark-color: #000;
$dark-light-color: #0000003b;
$login-color: #1e1e1e;
$primary-color: #03001c;
$secondary-color: #6b7280;
$third-color: #00df70;
$accent-color: #5b8fb9;
$table-color: #f6f6f6;
$table-border-color: #0000001f;
$table-header-color: #121212;

$bg-gray: #fcfcfc;
$bg-gray-1: #f1f1f1;
$bg-gray-2: #d6d6d6;
$bg-gray-3: #b2b2b2;
$bg-gray-4: #f6f6f6;
$bg-gray-5: #fcfcfc;
$bg-gray-6: #d9dae1;
$monsoon-gray: #888;
$seashell-gray: #f1f1f1;
$light-blue: #418bf2;
$invalid-red: #e52b50;

$h-5: 5%;
$h-10: 10%;
$h-90: 90%;
$h-95: 95%;
$h-100: 100%;

$v-7: 0.7rem;
$v-8: 0.8rem;
