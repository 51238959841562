@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@import "node_modules/bootstrap-icons/font/bootstrap-icons.scss";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "ngx-multiple-dates/prebuilt-themes/indigo-pink.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

@import "./breakpoints";
@import "./mixins";
@import "./variables";
@import "./classes";

@import "animate.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @layer base {
    ul,
    ol {
      list-style: revert;
    }
  }
}

html {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 62.5%;
}

body {
  background-color: $bg-gray-1;
}

.card-container {
  height: 75rem;
}

.ai-dialog-container {
  .mdc-dialog__surface {
    border-radius: 14px !important;
    width: 387px !important;
    height: 185px !important;
  }
}

.anamnese-dialog-container {
  .mdc-dialog__surface {
    border-radius: 14px !important;
    min-width: 1000px !important;
    width: 1000px !important;
    min-height: 500px !important;
    height: 500px !important;
    border-radius: 14px !important;
    padding: 12px 24px 24px 24px !important;

    @include for_1366x768 {
      min-height: 500px !important;
      height: 500px !important;
    }
    @include for_1440x900 {
      min-height: 600px !important;
      height: 600px !important;
    }
    @include for_1920x1080 {
      min-height: 700px !important;
      height: 700px !important;
    }
  }
}

.card-container-md {
  height: 80rem;

  @include for-notebook {
    height: 53rem;
  }
}

.card-container-lg {
  height: 85rem;

  @include for-notebook {
    height: 55rem;
  }
}

.card-container-schedule {
  height: 82rem;
  overflow: hidden;
}

.card-content {
  background-color: $light-color;
  border-radius: 2rem;
  padding: 1rem;
  height: 80rem;

  @include for-notebook {
    height: 52rem;
  }

  @include for_small_phones {
    height: 70rem;
  }
}

.left-content {
  background-color: $light-color;
  border-radius: 2rem;
  padding: 1rem;
  height: 334px;
}

.custom-viewport {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.cdk-virtual-scroll-content-wrapper {
  display: table;
  width: 100%;
}

table tr {
  height: 70px;
}

::-webkit-scrollbar {
  width: 1rem;
  padding-left: 1rem;
}

::-webkit-scrollbar-thumb {
  background-color: $monsoon-gray;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: $seashell-gray;
}
.schedule-select-modal {
  width: 500px;
}
/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: var(--border-color) !important;
}

/* Change the border width */
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-width: 1.5px !important;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  // border-color: var(--color-primary) !important;
  border-color: #03001c !important;
}

// ng select
.ng-select.custom-select {
  font-size: 1.5rem;
}

.ng-select.custom-select .ng-select-container {
  border-color: $bg-gray-2;
  border-radius: 1rem;
  min-height: 40px;
  width: 98%;
}

.ng-select.custom-select-full {
  font-size: 1.5rem;
}

.ng-select.custom-select-full .ng-select-container {
  border-color: $bg-gray-2;
  border-radius: 1rem;
  min-height: 40px;
  width: 100%;
}

.ng-select.custom-select .ng-select-single .ng-select-container {
  height: 40px;
}

.ng-select.custom-select .ng-dropdown-panel {
  width: 98%;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  outline: none;
  box-shadow: none;
  border-color: #ccc;
}
.special-label {
  margin-top: -8px;
}
.z-1 {
  z-index: 1;
}
.invalid-text {
  background-color: $light-color;
  color: $invalid-red;
  font-weight: 700;
}

.custom-checkbox {
  @apply appearance-none h-5 w-5 border border-gray-400 rounded-sm transition duration-300 transform;
}

.custom-checkbox:checked {
  background-color: $primary-color;
  border-color: transparent;
  transform: scale(1.1);
}

.custom-checkbox:focus {
  @apply outline-none ring-2 ring-offset-2;
  ring-color: $primary-color;
  transform: scale(1.05);
}

.custom-checkbox:not(:checked) {
  opacity: 0.75;
}
