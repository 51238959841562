@mixin font($family, $style, $sans-serif: false) {
  font-family: $family;
  font-style: $style;

  @if $sans-serif {
    font-family:
      #{$family},
      sans-serif;
  }
}

@mixin button(
  $bg-color,
  $text-color,
  $padding,
  $border: none,
  $hover: false,
  $bg-hover-color: #000,
  $bg-text-color: #fff
) {
  background-color: $bg-color;
  color: $text-color;
  padding: $padding;
  border: $border;
  cursor: pointer;
  transition: 0.2s;

  @if $hover {
    &:hover {
      background-color: $bg-hover-color;
      color: $bg-text-color;
    }
  }
}
